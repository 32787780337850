// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$btn-href-name: 'btn_href';
$btn-href-x: 0px;
$btn-href-y: 0px;
$btn-href-offset-x: 0px;
$btn-href-offset-y: 0px;
$btn-href-width: 32px;
$btn-href-height: 18px;
$btn-href-total-width: 32px;
$btn-href-total-height: 28px;
$btn-href-image: '../sprite.png';
$btn-href: (0px, 0px, 0px, 0px, 32px, 18px, 32px, 28px, '../sprite.png', 'btn_href', );
$icon-down-name: 'icon-down';
$icon-down-x: 0px;
$icon-down-y: 18px;
$icon-down-offset-x: 0px;
$icon-down-offset-y: -18px;
$icon-down-width: 16px;
$icon-down-height: 10px;
$icon-down-total-width: 32px;
$icon-down-total-height: 28px;
$icon-down-image: '../sprite.png';
$icon-down: (0px, 18px, 0px, -18px, 16px, 10px, 32px, 28px, '../sprite.png', 'icon-down', );
$icon-up-name: 'icon-up';
$icon-up-x: 16px;
$icon-up-y: 18px;
$icon-up-offset-x: -16px;
$icon-up-offset-y: -18px;
$icon-up-width: 16px;
$icon-up-height: 10px;
$icon-up-total-width: 32px;
$icon-up-total-height: 28px;
$icon-up-image: '../sprite.png';
$icon-up: (16px, 18px, -16px, -18px, 16px, 10px, 32px, 28px, '../sprite.png', 'icon-up', );
$spritesheet-width: 32px;
$spritesheet-height: 28px;
$spritesheet-image: '../sprite.png';
$spritesheet-sprites: ($btn-href, $icon-down, $icon-up, );
$spritesheet: (32px, 28px, '../sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
