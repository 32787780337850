@import "sprite.scss";
@import "../../main/scss/mixin.scss";

#block_2 {
  position: relative;
  @include mobile {
    .desktop {
      display: none !important;
    }
  }
  .background {
    img {
      width: 100%;
    }
  }
  .content {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 20px;
    padding-bottom: 120px;
    gap: 20%;
    @include mobile {
      padding-right: 0;
      gap: 0;
    }
    .popup-online {
      left: 18% !important;
      bottom: 30% !important;
      @include mobile {
        bottom: 58% !important;
        left: 9% !important;
      }
    }
    .popup-offline {
      right: 18.8% !important;
      bottom: 30% !important;
      @include mobile {
        bottom: 5% !important;
        right: 8% !important;
      }
    }
    .box-text {
      width: 22%;
      &.online {
        @include mobile {
          position: absolute;
          left: 3%;
          top: 6%;
          width: 53%;
          font-weight: bold;
        }
      }
      &.offline {
        position: relative;
        &:after {
          position: absolute;

        }
        @include mobile {
          position: absolute;
          right: 3%;
          bottom: 15%;
          width: 70%;
          font-weight: bold;
        }
      }
      .title {
        font-size: 109px;
        font-family: tungsten_bold;
        @include mobile {
          font-size: 120px;
        }
      }
      .description {
        font-size: 18px;
        font-family: dinpro_medium;
        @include mobile {
          font-size: 21px;
          line-height: 28px;
        }
      }
    }
    .btn {
      @include sprite($btn_bg);
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      color: #fefdfb;
      position: absolute;
      bottom: 13%;
      text-transform: uppercase;
      font-family: dinpro_bold;
      @include mobile {
        transform: scale(1.3);
        transform-origin: top;
        bottom: 48%;
      }
      &:hover {
        @include sprite($btn_bg-hover);
      }
    }
  }
 
}
