@import "sprite.scss";
@import "../../main/scss/mixin.scss";

#block_1 {
  position: relative;
  font-family: dinpro_bold;
  @include mobile {
    .desktop {
      display: none !important;
    }
  }
  .background {
    img {
      width: 100%;
    }
  }
  .content {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    @include mobile {
    }
    .container {
      margin-top: 80px;
      position: relative;
      .time {
        font-family: dinpro_medium;
        color: #fefdfb;
        position: absolute;
        left: 101px;
        top: 247px;
        width: 183px;
        height: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
      }
      .list-item {
        margin-top: 140px;
        display: flex;
        justify-content: center;
        gap: 18px;
        @include mobile {
          flex-direction: column-reverse;
          align-items: center;
          gap: 80px;
          margin-top: 100px;
        }
        .btn {
          @include sprite($btn_bg);
          display: flex;
          justify-content: center;
          align-items: center;
          text-decoration: none;
          color: #fefdfb;
          @include mobile {
            transform: scale(1.3);
            transform-origin: top;
          }
          &:hover {
            @include sprite($btn_bg-hover);
          }
        }
        .btn_video {
          @include sprite($btn_video);
          @include mobile {
            transform: scale(1.3);
          }
        }
      }
      
    }
  }
 
}
