// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$btn-bg-hover-name: 'btn_bg-hover';
$btn-bg-hover-x: 0px;
$btn-bg-hover-y: 0px;
$btn-bg-hover-offset-x: 0px;
$btn-bg-hover-offset-y: 0px;
$btn-bg-hover-width: 267px;
$btn-bg-hover-height: 74px;
$btn-bg-hover-total-width: 267px;
$btn-bg-hover-total-height: 222px;
$btn-bg-hover-image: '../sprite.png';
$btn-bg-hover: (0px, 0px, 0px, 0px, 267px, 74px, 267px, 222px, '../sprite.png', 'btn_bg-hover', );
$btn-bg-name: 'btn_bg';
$btn-bg-x: 0px;
$btn-bg-y: 74px;
$btn-bg-offset-x: 0px;
$btn-bg-offset-y: -74px;
$btn-bg-width: 267px;
$btn-bg-height: 74px;
$btn-bg-total-width: 267px;
$btn-bg-total-height: 222px;
$btn-bg-image: '../sprite.png';
$btn-bg: (0px, 74px, 0px, -74px, 267px, 74px, 267px, 222px, '../sprite.png', 'btn_bg', );
$btn-video-name: 'btn_video';
$btn-video-x: 0px;
$btn-video-y: 148px;
$btn-video-offset-x: 0px;
$btn-video-offset-y: -148px;
$btn-video-width: 73px;
$btn-video-height: 74px;
$btn-video-total-width: 267px;
$btn-video-total-height: 222px;
$btn-video-image: '../sprite.png';
$btn-video: (0px, 148px, 0px, -148px, 73px, 74px, 267px, 222px, '../sprite.png', 'btn_video', );
$spritesheet-width: 267px;
$spritesheet-height: 222px;
$spritesheet-image: '../sprite.png';
$spritesheet-sprites: ($btn-bg-hover, $btn-bg, $btn-video, );
$spritesheet: (267px, 222px, '../sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
