@import "sprite.scss";
@import "../../main/scss/mixin.scss";

#block_3 {
  position: relative;
  @include mobile {
    .desktop {
      display: none !important;
    }
  }
  .background {
    img {
      width: 100%;
    }
  }
  .content {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    padding-left: 18%;
    padding-top: 4%;
    @include mobile {
      padding: 3%;
    }
    .title {
      font-size: 118px;
      font-family: tungsten_bold;
      text-transform: uppercase;
      color: #f0efed;
      @include mobile {
        font-size: 82px;
      }
    }
    .description {
      color: #c2a07a;
      font-size: 20px;
      font-family: dinpro_bold;
    }
    .link {
      position: absolute;
      right: 19.5%;
      top: 15%;
      text-decoration: none;
      color: #ff4655;
      font-family: dinpro_bold;
      font-size: 16px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      gap: 8px;
      @include mobile {
        right: 7%;
        top: 94%;
        transform: scale(1.3);
      }
      &:after {
        content: "";
        @include sprite($icon-arrow);
        display: block;
        position: relative;
        top: 1px;
      }
    }
    .list-item {
      margin-top: 26px;
      display: flex;
      flex-wrap: wrap;
      gap: 60px;
      width: 80%;
      height: 80%;
      overflow-x: hidden;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-thumb {
        background: #AAA;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #CCC;
        border-radius: 10px;
      }
      @include mobile {
        width: 100%;
        padding-top: 10%;
        height: auto;
        justify-content: center;
        margin-top: 84px;
        row-gap: 138px;
        column-gap: 118px;
      }
      .item {
        @include sprite($bg-item);
        position: relative;
        overflow: hidden;
        @include mobile {
          width: 263.9px;
          height: 307.3px;
          background-size: cover;
          transform: scale(1.3);
        }
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          @include mobile {
            transform: translate(-50%, -50%) scale(0.7);
          }
        }
        p {
           font-family: dinpro_bold;
           height: 50px;
            width: 100%;
            position: absolute;
            bottom: 0;
            text-align: center;
            line-height: 50px;
            color: #dcd9d4;
            text-transform: uppercase;
            font-size: 24px;
            @include mobile {
              height: 34px;
              line-height: 34px;
              font-size: 21px;
            }
        }
        .icon {
          position: absolute;
          bottom: 14px;
          left: 0;
          @include mobile {
            bottom: 6px;
          }
        }
        .num1 {
          @include sprite($num1);
        }
        .num2 {
          @include sprite($num2);
        }
        .num3 {
          @include sprite($num3);
        }
        .num4 {
          @include sprite($num4);
        }
        .num5 {
          @include sprite($num5);
        }
        .num6 {
          @include sprite($num6);
        }
      }
    }
  }
 
}
