// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$bg-item-name: 'bg-item';
$bg-item-x: 0px;
$bg-item-y: 0px;
$bg-item-offset-x: 0px;
$bg-item-offset-y: 0px;
$bg-item-width: 377px;
$bg-item-height: 439px;
$bg-item-total-width: 644px;
$bg-item-total-height: 439px;
$bg-item-image: '../sprite.png';
$bg-item: (0px, 0px, 0px, 0px, 377px, 439px, 644px, 439px, '../sprite.png', 'bg-item', );
$btn-bg-hover-name: 'btn_bg-hover';
$btn-bg-hover-x: 377px;
$btn-bg-hover-y: 0px;
$btn-bg-hover-offset-x: -377px;
$btn-bg-hover-offset-y: 0px;
$btn-bg-hover-width: 267px;
$btn-bg-hover-height: 74px;
$btn-bg-hover-total-width: 644px;
$btn-bg-hover-total-height: 439px;
$btn-bg-hover-image: '../sprite.png';
$btn-bg-hover: (377px, 0px, -377px, 0px, 267px, 74px, 644px, 439px, '../sprite.png', 'btn_bg-hover', );
$btn-bg-name: 'btn_bg';
$btn-bg-x: 377px;
$btn-bg-y: 74px;
$btn-bg-offset-x: -377px;
$btn-bg-offset-y: -74px;
$btn-bg-width: 267px;
$btn-bg-height: 74px;
$btn-bg-total-width: 644px;
$btn-bg-total-height: 439px;
$btn-bg-image: '../sprite.png';
$btn-bg: (377px, 74px, -377px, -74px, 267px, 74px, 644px, 439px, '../sprite.png', 'btn_bg', );
$icon-arrow-name: 'icon-arrow';
$icon-arrow-x: 377px;
$icon-arrow-y: 148px;
$icon-arrow-offset-x: -377px;
$icon-arrow-offset-y: -148px;
$icon-arrow-width: 32px;
$icon-arrow-height: 18px;
$icon-arrow-total-width: 644px;
$icon-arrow-total-height: 439px;
$icon-arrow-image: '../sprite.png';
$icon-arrow: (377px, 148px, -377px, -148px, 32px, 18px, 644px, 439px, '../sprite.png', 'icon-arrow', );
$num1-name: 'num1';
$num1-x: 437px;
$num1-y: 166px;
$num1-offset-x: -437px;
$num1-offset-y: -166px;
$num1-width: 12px;
$num1-height: 20px;
$num1-total-width: 644px;
$num1-total-height: 439px;
$num1-image: '../sprite.png';
$num1: (437px, 166px, -437px, -166px, 12px, 20px, 644px, 439px, '../sprite.png', 'num1', );
$num2-name: 'num2';
$num2-x: 389px;
$num2-y: 166px;
$num2-offset-x: -389px;
$num2-offset-y: -166px;
$num2-width: 12px;
$num2-height: 22px;
$num2-total-width: 644px;
$num2-total-height: 439px;
$num2-image: '../sprite.png';
$num2: (389px, 166px, -389px, -166px, 12px, 22px, 644px, 439px, '../sprite.png', 'num2', );
$num3-name: 'num3';
$num3-x: 425px;
$num3-y: 166px;
$num3-offset-x: -425px;
$num3-offset-y: -166px;
$num3-width: 12px;
$num3-height: 21px;
$num3-total-width: 644px;
$num3-total-height: 439px;
$num3-image: '../sprite.png';
$num3: (425px, 166px, -425px, -166px, 12px, 21px, 644px, 439px, '../sprite.png', 'num3', );
$num4-name: 'num4';
$num4-x: 377px;
$num4-y: 166px;
$num4-offset-x: -377px;
$num4-offset-y: -166px;
$num4-width: 12px;
$num4-height: 23px;
$num4-total-width: 644px;
$num4-total-height: 439px;
$num4-image: '../sprite.png';
$num4: (377px, 166px, -377px, -166px, 12px, 23px, 644px, 439px, '../sprite.png', 'num4', );
$num5-name: 'num5';
$num5-x: 401px;
$num5-y: 166px;
$num5-offset-x: -401px;
$num5-offset-y: -166px;
$num5-width: 12px;
$num5-height: 22px;
$num5-total-width: 644px;
$num5-total-height: 439px;
$num5-image: '../sprite.png';
$num5: (401px, 166px, -401px, -166px, 12px, 22px, 644px, 439px, '../sprite.png', 'num5', );
$num6-name: 'num6';
$num6-x: 413px;
$num6-y: 166px;
$num6-offset-x: -413px;
$num6-offset-y: -166px;
$num6-width: 12px;
$num6-height: 22px;
$num6-total-width: 644px;
$num6-total-height: 439px;
$num6-image: '../sprite.png';
$num6: (413px, 166px, -413px, -166px, 12px, 22px, 644px, 439px, '../sprite.png', 'num6', );
$spritesheet-width: 644px;
$spritesheet-height: 439px;
$spritesheet-image: '../sprite.png';
$spritesheet-sprites: ($bg-item, $btn-bg-hover, $btn-bg, $icon-arrow, $num1, $num2, $num3, $num4, $num5, $num6, );
$spritesheet: (644px, 439px, '../sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
