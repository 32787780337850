@import "sprite.scss";
@import "../../main/scss/mixin.scss";

.btn_top {
  @include sprite($btn_top);
  position: absolute;
  right: 40px;
  bottom: 15px;
  visibility: visible;
}
.scrollwatch-pin {
  position: absolute !important;
}

#common {
  visibility: visible;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  transition: all .3s;
  .content {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 163px;
    padding-left: 12px;
    gap: 4px;
   
    }
}
#navbar {
  visibility: visible;
  position: relative;
  font-family: navbar;
  .background {
    img {
      width: 100%;
    }
  }
  .content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding: 0 24px;
    @include mobile {
      justify-content: space-between;
    }
  }
  .logo {
    @include sprite($logo);
    @include mobile {
      @include sprite($logo-mb);
    }
  }
  .menu {
    display: flex;
    justify-content: space-between;
    width: 100%;
    text-transform: uppercase;
    color: #ece8e1;
    margin-left: 24px;
    @include mobile {
      display: none;
      &.active {
        display: block;
        position: absolute;
        top: 100px;
        left: 0;
        margin-left: 0;
        background-color: #111111;
        .list-item {
          flex-direction: column;
          align-items: center;
          gap: 0;
          li {
            width: 100%;
            height: auto;
            min-height: 56px;
            line-height: 56px;
        
            border-top: 1px solid;
            p {
              height: 100%;
              justify-content: center;
            }
          }
        }
      }
    }
    &-mb {
      @include sprite($menu-mb);
    }
    .list-item {
      list-style: none;
      display: flex;
      gap: 24px;
      font-size: 16px;
      li {
        padding: 4px 14px;
        border-radius: 4px;
        position: relative;
        .sub {
          display: none;
        }
        &:hover {
          background-color: #363636;
          @include mobile {
            background-color: unset;
          }
          &:before {
            content: "";
            position: absolute;
            left: 50%;
            bottom: -15px;
            transform: translateX(-50%);
            width: 100%;
            height: 20px;
            @include mobile {
              display: none;
            }
          }
          &:after {
            content: "";
            position: absolute;
            left: 50%;
            bottom: -12px;
            transform: translateX(-50%);
            width: 100%;
            height: 2px;
            background-color: #ff4655;
            @include mobile {
              display:none;
            }
          }
          .sub {
            list-style: none;
            display: flex;
            flex-direction: column;
            width: 100%;
            background-color: #252525;
            padding: 12px 0;
            position: absolute;
            left: 0;
            top: 37px;
            @include mobile {
              position: unset;
              padding: 0;
              height: auto;
              text-align: center;
            }
            li {
              @include mobile {
                width: 100%;
                padding: 0;
                line-height: 56px;
              }
              &:hover {
                width: 70%;
                margin: 0 auto;
                padding: 4px 18px;
                cursor: pointer;
                @include mobile {
                  width: unset;
                  margin: unset;
                  padding: 0;
                  border-radius: unset;
                }
                &:after {
                  display: none;
                }
              }
            }
          }
        }
        p {
          display: flex;
          align-items: center;
          gap: 6px;
          .dropdown {
            @include sprite($icon-dropdown);
            transform: scale(1.2);
            transform-origin: left;
          }
        }
      }
    }
    .btn_partner {
      margin-right: 24px;
      color: #ece8e1;
      text-decoration: none;
      border: 1px solid;
      padding: 4px 12px;
      border-radius: 18px;
      @include mobile {
        text-align: center;
        width: 100%;
        display: block;
        border: unset;
        margin-right: 0;
        padding: 0;
        border-radius: 0;
        border-top: 1px solid;
        height: 56px;
        line-height: 56px;
      }
    }
  }
}
#float_left {
  font-family: dinpro_bold;
  visibility: visible;
  position: relative;
  top: 100px;
  left: 40px;
  .content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    .list-item {
      display: flex;
      gap: 24px;
      font-size: 18px;
      flex-direction: column;
      text-transform: uppercase;
      list-style: none;
      .nav-item {
        text-decoration: none;
        position: relative;
        color: #947c5f;
        display: flex;
        align-items: center;
        gap: 8px;
        &:before {
          content: "";
          position: relative;
          top: 1px;
          @include sprite($icon-nav);
        }
        &:hover {
          color: #fefdfb;
          &:before {
            @include sprite($icon-nav_hover);
          }
        }
      }
    }
  }
}
#float_right {
  visibility: visible;
  position: absolute;
  top: 300px;
  right: 40px;
  margin-right: auto;
  .content {
    display: flex;
    flex-direction: column;
    .btn_register {
      @include sprite($btn_register);
      &:hover {
        @include sprite($btn_register-hover);
      }
    }
    .list-item {
      @include sprite($bg_floatright);
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      .btn_fb {
        @include sprite($btn_fb);
        &:hover {
          @include sprite($btn_fb-hover);
        }
      }
      .btn_comu {
        @include sprite($btn_comu);
        &:hover {
          @include sprite($btn_comu-hover);
        }
      }
      .btn_dis {
        @include sprite($btn_dis);
        &:hover {
          @include sprite($btn_dis-hover);
        }
      }
    }
  }
}
