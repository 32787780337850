// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$btn-comu-hover-name: 'btn_comu-hover';
$btn-comu-hover-x: 0px;
$btn-comu-hover-y: 25px;
$btn-comu-hover-offset-x: 0px;
$btn-comu-hover-offset-y: -25px;
$btn-comu-hover-width: 60px;
$btn-comu-hover-height: 61px;
$btn-comu-hover-total-width: 387px;
$btn-comu-hover-total-height: 86px;
$btn-comu-hover-image: '../sprite.png';
$btn-comu-hover: (0px, 25px, 0px, -25px, 60px, 61px, 387px, 86px, '../sprite.png', 'btn_comu-hover', );
$btn-comu-name: 'btn_comu';
$btn-comu-x: 60px;
$btn-comu-y: 25px;
$btn-comu-offset-x: -60px;
$btn-comu-offset-y: -25px;
$btn-comu-width: 60px;
$btn-comu-height: 61px;
$btn-comu-total-width: 387px;
$btn-comu-total-height: 86px;
$btn-comu-image: '../sprite.png';
$btn-comu: (60px, 25px, -60px, -25px, 60px, 61px, 387px, 86px, '../sprite.png', 'btn_comu', );
$btn-dis-hover-name: 'btn_dis-hover';
$btn-dis-hover-x: 120px;
$btn-dis-hover-y: 25px;
$btn-dis-hover-offset-x: -120px;
$btn-dis-hover-offset-y: -25px;
$btn-dis-hover-width: 60px;
$btn-dis-hover-height: 60px;
$btn-dis-hover-total-width: 387px;
$btn-dis-hover-total-height: 86px;
$btn-dis-hover-image: '../sprite.png';
$btn-dis-hover: (120px, 25px, -120px, -25px, 60px, 60px, 387px, 86px, '../sprite.png', 'btn_dis-hover', );
$btn-dis-name: 'btn_dis';
$btn-dis-x: 180px;
$btn-dis-y: 25px;
$btn-dis-offset-x: -180px;
$btn-dis-offset-y: -25px;
$btn-dis-width: 60px;
$btn-dis-height: 60px;
$btn-dis-total-width: 387px;
$btn-dis-total-height: 86px;
$btn-dis-image: '../sprite.png';
$btn-dis: (180px, 25px, -180px, -25px, 60px, 60px, 387px, 86px, '../sprite.png', 'btn_dis', );
$btn-fb-hover-name: 'btn_fb-hover';
$btn-fb-hover-x: 240px;
$btn-fb-hover-y: 25px;
$btn-fb-hover-offset-x: -240px;
$btn-fb-hover-offset-y: -25px;
$btn-fb-hover-width: 38px;
$btn-fb-hover-height: 39px;
$btn-fb-hover-total-width: 387px;
$btn-fb-hover-total-height: 86px;
$btn-fb-hover-image: '../sprite.png';
$btn-fb-hover: (240px, 25px, -240px, -25px, 38px, 39px, 387px, 86px, '../sprite.png', 'btn_fb-hover', );
$btn-fb-name: 'btn_fb';
$btn-fb-x: 278px;
$btn-fb-y: 25px;
$btn-fb-offset-x: -278px;
$btn-fb-offset-y: -25px;
$btn-fb-width: 38px;
$btn-fb-height: 39px;
$btn-fb-total-width: 387px;
$btn-fb-total-height: 86px;
$btn-fb-image: '../sprite.png';
$btn-fb: (278px, 25px, -278px, -25px, 38px, 39px, 387px, 86px, '../sprite.png', 'btn_fb', );
$logo-name: 'logo';
$logo-x: 0px;
$logo-y: 0px;
$logo-offset-x: 0px;
$logo-offset-y: 0px;
$logo-width: 387px;
$logo-height: 25px;
$logo-total-width: 387px;
$logo-total-height: 86px;
$logo-image: '../sprite.png';
$logo: (0px, 0px, 0px, 0px, 387px, 25px, 387px, 86px, '../sprite.png', 'logo', );
$spritesheet-width: 387px;
$spritesheet-height: 86px;
$spritesheet-image: '../sprite.png';
$spritesheet-sprites: ($btn-comu-hover, $btn-comu, $btn-dis-hover, $btn-dis, $btn-fb-hover, $btn-fb, $logo, );
$spritesheet: (387px, 86px, '../sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
