@import "sprite.scss";
@import "../../main/scss/mixin.scss";

#block_4 {
  position: relative;
  @include mobile {
    .desktop {
      display: none !important;
    }
  }
  .background {
    img {
      width: 100%;
    }
  }
  .content {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 34px;
    @include mobile {
      padding: 3%;
      padding-top: 5%;
    }
    .title {
      font-size: 115px;
      font-family: tungsten_bold;
      text-transform: uppercase;
      color: #313030;
      @include mobile {
        font-size: 82px;
      }
    }
    .swiper {
      width: 100%;
      height: 100%;
      &-wrapper {
        padding-top: 5.5%;
        @include mobile {
          padding-top: 21%;
        }
      }
      &-slide {
        .list-item-1 {
          display: none;
          justify-content: center;
          gap: 48px;
          @include mobile {
            flex-wrap: wrap-reverse;
            gap: 0;
            column-gap: 24px;
          }
          .item {
            text-transform: uppercase;
            font-family: dinpro_bold;
            position: relative;
            transform: scale(0.9);
            transform-origin: bottom;
            @include mobile {
              transform-origin: unset;
              top: -42px;
            }
            &.special {
              @include mobile {
                order: 1;
                top: 0;
              }
            }
            .text {
              position: absolute;
              bottom: 145px;
              left: 0;
              width: 100%;
              .name {
                color: #313030;
                height: 46px;
                line-height: 46px;
                text-align: center;
                font-size: 32px;
              }
              .label {
                color: #bca585;
                height: 50px;
                line-height: 50px;
                text-align: center;
                font-size: 26px;
              }
            }
          }
        }
        .list-item-2 {
          display: none;
          justify-content: center;
          gap: 48px;
          @include mobile {
            flex-wrap: wrap-reverse;
            gap: 0;
            column-gap: 2px;
          }
          .item {
            text-transform: uppercase;
            font-family: dinpro_bold;
            position: relative;
            transform: scale(0.9);
            transform-origin: bottom;
            @include mobile {
              transform-origin: unset;
              top: -21px;
            }
            &.special {
              @include mobile {
                order: 1;
                top: 0;
              }
            }
            .text {
              position: absolute;
              bottom: 145px;
              width: 50%;
              .name {
                color: #313030;
                height: 46px;
                line-height: 46px;
                text-align: center;
                font-size: 28px;
              }
              .label {
                color: #bca585;
                height: 50px;
                line-height: 50px;
                text-align: center;
                font-size: 24px;
              }
            }
            .text-1 {
              left: 0;
            }
            .text-2 {
              right: 0;
            }
          }
        }
        .list-item-3 {
          display: none;
          justify-content: center;
          gap: 48px;
          @include mobile {
            flex-wrap: wrap;
            gap: 0;
            column-gap: 8px;
            justify-content: flex-end;
          }
          .item {
            text-transform: uppercase;
            font-family: dinpro_bold;
            position: relative;
            @include mobile {
              &-1 {
                top: 120px;
              }
              &-2 {
                top: 60px;
              }
              &-3 {
                margin-right: 150px;
              }
              &-4 {
                top: -30px;
                right: 20px;
              }
              &-5 {
                top: -110px;
                margin-right: 40px;
              }
            }
            .text {
              position: absolute;
              bottom: 145px;
              left: 0;
              width: 100%;
              .name {
                color: #313030;
                height: 47px;
                line-height: 47px;
                text-align: center;
                font-size: 32px;
              }
              .label {
                color: #bca585;
                height: 50px;
                line-height: 50px;
                text-align: center;
                font-size: 26px;
              }
            }
          }
        }
        &.list-item-1 .list-item-1 {
          display: flex;
        }
        &.list-item-2 .list-item-2 {
          display: flex;
        }
        &.list-item-3 .list-item-3 {
          display: flex;
        }
      }
      .swiper-pagination {
        bottom: unset;
        top: 12px;
        @include mobile {
          top: 63px;
        }
        &-bullet {
          font-family: dinpro_bold;
          color: #313030;
          border: 1px solid #313030;
          text-transform: uppercase;
          text-decoration: none;
          font-size: 32px;
          padding: 12px 56px;
          border-radius: 0;
          background-color: transparent;
          width: auto;
          height: auto;
          margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 28px);
          @include mobile {
            font-size: 21px;
            padding: 12px 47px;
            margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 6px);
          }
        }
      }
    }
  }
}
