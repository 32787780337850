@import "sprite.scss";
@import "../../main/scss/mixin.scss";
.popup {
	z-index: -1;
	visibility: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
	&.active {
		z-index: 10;
		visibility: visible;
		.content {
			opacity: 1;
		}
	}
	.content {
		opacity: 0;
		transition: opacity 1s;
		width: 100%;
    	height: 100%;
	    position: relative;
	    .opacity {
				position: absolute;
				top: -10%;
				width: 100%;
				height: 2000px;
				background: rgba(5, 5, 5, 0.9);
				@include mobile {
					top: -60%;
				}
				@include mobile {
					height: 1000vh;
				}
		}
	    .container {
	    	position: absolute;
		    left: 50%;
		    top: 50%;
		    transform: translate(-50%,-50%);
		    font-family: tungsten_bold;
		    .close-popup {
		    	position: absolute;
  	    		right: -4%;
				top: -6%;
		    	z-index: 1;
		    	// @include sprite($btn_close);
		    	display: block;
		    	@include mobile {
    		    	right: 3%;
    				top: 2.5%;
		    	}
		    }
		    .inside {
			    display: flex;
				flex-direction: column;
			    width: 100%;
			    height: 100%;
			    position: absolute;
			    top: 0;
			    left: 50%;
			    transform: translateX(-50%);
			    box-sizing: border-box;
			    @include mobile {
			    	padding: 2% !important;
			    }
			    .title {
				    color: #e7bf8e;
				    text-transform: uppercase;
				    font-size: 108px;
				    text-align: center;
				    @include mobile {
			    	    font-size: 57px;
				    }
			    }

			    .frame {
			    	width: 100%;
    				height: 88%;
    				padding-left: 5%;
    				padding-right: 5%;
    				box-sizing: border-box;
    				display: flex;
    				flex-direction: column;
    				// align-items: center;
    				nav {
							margin-top: 14px;
							@include mobile {
								margin-top: 22px;
								font-size: 22px;
							}
							// Example Generate Code to backend
							.pagination > li {
							  display: inline-block;
							  margin-right: 5px;
							  margin-left: 5px;
							}
							.pagination > li > a {
							  color: black;
							  float: left;
							  text-decoration: none !important;
							}
							.pagination > li > a.page-link, .page-next {
							  padding: 5px 14px;
							}
							.pagination > li.active > a {
							  background-color: #1c7794;
							  color: #fff;
							}
							.pagination {
							  text-align: center;
							}
							.pagination a:hover:not(.active) {
								background-color: #1c7794;
								color: white;

							}
							.pagination {
						    display: flex;
					    	align-items: center;
					    	justify-content: center;
					    	.page-item {
					    		a {
					    			// color: $color_cus-popup;
					    			&:hover {
					    				// background-color: $color-bodyTable-popup;
					    			}
					    		}
					    		&.active{
					    			a{
					    				// background:  $color-bodyTable-popup;
					    			}
					    		}
					    	}
							}
						}
						overflow-y: auto;
				  	scrollbar-width: thin;
				    scrollbar-color: #AAA #CCC;
				    padding-bottom: 5%;
				    &::-webkit-scrollbar {
				      width: 6px;
				    }

				    &::-webkit-scrollbar-thumb {
				      background: #867c63;
				      border-radius: 10px;
				    }

				    &::-webkit-scrollbar-track {
				      box-shadow: inset 0 0 5px #CCC;
				      border-radius: 10px;
				    }
    				.captcha {
						display: flex;
						justify-content: center;
						align-items: center;
						margin: 12px 0;
						img {
							background-color: white;
							border: 1px solid black;
						}
						.refresh {
							@include image('','icon-refesh.png',44px,44px);
						  	margin-left: 12px;
						}
					}
					.captcha-input {
						display: flex;
						flex-direction: column;
						align-items: center;
						margin-bottom: 12px;
						input {
							width: 36%;
							height: 34px;
							margin: 2px 0;
							border: 1px solid;
							@include mobile {
								width: 42%;
							}
						}
					}
			    }
		    }
	    }
	}
}
.popup_match {
	.frame {
		padding-left: 0 !important;
		padding-right: 0 !important;
	    overflow: hidden !important;
	    @include mobile {
	    	overflow-y: auto !important;
	    	margin: 0 auto;
    		width: 748px !important;
	    }
	}
	.bg-custom {
		@include mobile {
			height: 726px;
		}
	}
	.list-item {
		display: flex;
	    justify-content: space-between;
	    width: 98%;
	    margin: 32px auto 0;
	    @include mobile {
    	    width: 87%;
	        flex-wrap: wrap;
	    }
		.item {
			width: 300px;
			text-transform: uppercase;
			&-title {
				color: #e7bf8e;
				font-size: 56px;
				border-bottom: 6px solid #6f553b;
				padding-bottom: 6px;
				@include mobile {
					font-size: 48px;
				}
			}
			&-time {
				color: #e7bf8e;
				font-family: dinpro_bold;
				font-size: 28px;
		    	line-height: 18px;
    			margin-bottom: 12px;
			}
			&-hour {
				color: white;
				font-family: dinpro_bold;
				font-size: 42px;
				line-height: 38px;
				padding-bottom: 4px;
				text-align: center;
				background-color: #6f553b;
			}
			&-type {
				color: white;
				font-size: 72px;
				text-align: center;
				line-height: 78px;
			}
			&-match {
				color: white;
				font-size: 18px;
				text-align: center;
				font-family: dinpro_bold;
				margin-bottom: 12px;
			}
			&-box {
				border-left: 1px solid #6f553b;
				margin-top: 26px;
				&-1 {
					margin-top: 16px;
			    	margin-left: 28px;
			    	margin-bottom: 32px;
					position: relative;
					text-decoration: none;
					display: block;
					&:before {
						content: "";
						position: absolute;
						left: -30px;
						top: 0;
						@include sprite($flag);
					}
					&.active {
						&:before {
							@include sprite($flag-hover);
						}
						.item-hour {
							background-color: #ff4655;
						}
						.item-type {
							color: #2c2b2b;
						}
						.item-match {
							color: #2c2b2b;
						}
						.item-box-2 {
							background-color: #dcd9d4; 
						}
					}
				}
				&-2 {
					margin-top: 4px;
					border: 2px solid #d1ae82;
				}
			}
		}
	}
}
// #popup_register {
// 	form {
// 		margin-top: 24px;
// 		width: 90%;
// 		color: #145d74;
// 		font-size: 21px;
// 		.row {
// 		    width: 100%;
// 		    display: flex;
// 		    justify-content: center;
// 		    gap: 18px;
// 		    margin-bottom: 8px;
// 		    .value {
// 		    	width: 100%;
// 		    	display: flex;
//     			flex-direction: column;
//     			gap: 4px;
//     			select,input {
    	
//     				font-size: 18px;
// 				    height: 42px;
// 				    margin: 2px 0;
// 				    border: 1px solid #f1ede2;
// 				    background-color: #e5e5e5;
// 				    padding-left: 12px;
//     			}
// 		    }
// 		}
// 	}
// 	.btn_register {
// 		@include sprite($btn_register);
// 		background-color: transparent;
// 	    display: block;
//     	margin: 42px auto 0;
// 	}
// }
// #popup_login {
// 	color: #145d74;
// 	text-align: center;
// 	.text-1 {
// 		margin-top: 24px;
// 		font-size: 30px;
// 	}
// 	.text-2 {
// 		font-size: 24px;
// 		margin-top: 18px;
// 		a {
// 			color: #f00e02;
// 		}
// 	}
// 	.btn_zing {
// 		@include sprite($btn_playzing);
// 		margin-top: 12px;
// 		@include mobile {
// 	    	margin-top: 18px;
// 		}
// 	}
// 	.btn_apple {
// 		@include sprite($btn_playapple);
// 		margin-top: 12px;
// 		@include mobile {
// 	    	margin-top: 18px;
// 		}
// 	}
// 	.btn_google {
// 		@include sprite($btn_playgg);
// 		margin-top: 12px;
// 		@include mobile {
// 	    	margin-top: 18px;
// 		}
// 	}
// 	.btn_facebook {
// 		@include sprite($btn_playfb);
// 		margin-top: 12px;
// 		@include mobile {
// 	    	margin-top: 18px;
// 		}
// 	}
// 	.btn_now {
// 		@include sprite($btn_playnow);
// 		margin-top: 12px;
// 		@include mobile {
// 	    	margin-top: 18px;
// 		}
// 	}
// }
// #popup_condition {
// 	color: #5c2e17;
// 	text-align: center;
// 	@include mobile {
// 		.frame {
// 		    width: 97%;
// 		    height: 80%;
// 		    margin: 0 auto;
// 		}
// 	}
// 	form {
// 		.title {
// 			text-transform: uppercase;
// 			color: #5c2e17;
// 			font-size: 32px;
// 		}
// 		.description {
// 			font-size: 21px;
// 		}
// 		.btn_share {
// 			@include sprite($btn_sharefb);
// 			display: block;
// 			margin: 16px auto;
// 		}
// 		.btn_get {
// 			@include sprite($btn_get);
// 			display: block;
// 			margin: 16px auto;
// 		}
// 		.btn_copy {
// 			@include sprite($btn_copylink);
// 			display: block;
// 			margin: 16px auto;
// 			font-size: 0;
// 			background-color: transparent;
// 		}
// 		.text-invite {
// 		    width: 90%;
// 		    height: 34px;
// 		    margin: 2px 0;
// 		    border: 1px solid;
// 		    margin-top: 12px;
// 		    background-color: #e2f3f2;
// 		}
// 	}
// }
// #popup_getlist {
// 	@include mobile {
// 		.frame {
// 			padding-left: 1%;
// 			padding-right: 1%;;
// 		}
// 	}
// 	.list-item {
// 	    display: flex;
// 	    flex-direction: column;
// 	    gap: 38px;
// 	    margin-top: 42px;
// 	    font-size: 28px;
// 	    margin: auto;
// 	    width: 95%;$
// 	    color: #00383d;
// 	    @include mobile {
//     	    margin: 72px auto;
// 	    }
// 		.item {
// 			display: flex;
// 		    justify-content: space-between;
// 		    align-items: center;
// 		    span {
// 		    	color: #c82b11;
// 		    }
// 		}
// 	}
// 	.btn_getlist {
// 		@include sprite($btn_getlist);
// 	}
// }
// #popup_history {
// 	@include mobile {
// 		.frame {
// 		    width: 98% !important; 
// 		}
// 	}
	
// }
// Custom
.popup .frame {
	.table_history {
		width: 100%;
		table, td, th {
		  border: 2px solid black;
		}
		table {
			border-collapse: collapse;
			width: 100%;
			thead {
				tr th {
					padding: 12px 2px;
					@include mobile {
						font-size: 24px;
					}
				}
			}
			tbody {
				tr:nth-child(even) {background-color: #eefdfc;}
				tr td {
					padding: 10px 0px;
					text-align: center;
					color: #013b4d;
					@include mobile {
						font-size: 22px;
					}
				}
			}
		}
	}
}

