// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$flag-hover-name: 'flag-hover';
$flag-hover-x: 0px;
$flag-hover-y: 0px;
$flag-hover-offset-x: 0px;
$flag-hover-offset-y: 0px;
$flag-hover-width: 18px;
$flag-hover-height: 12px;
$flag-hover-total-width: 36px;
$flag-hover-total-height: 12px;
$flag-hover-image: '../sprite.png';
$flag-hover: (0px, 0px, 0px, 0px, 18px, 12px, 36px, 12px, '../sprite.png', 'flag-hover', );
$flag-name: 'flag';
$flag-x: 18px;
$flag-y: 0px;
$flag-offset-x: -18px;
$flag-offset-y: 0px;
$flag-width: 18px;
$flag-height: 12px;
$flag-total-width: 36px;
$flag-total-height: 12px;
$flag-image: '../sprite.png';
$flag: (18px, 0px, -18px, 0px, 18px, 12px, 36px, 12px, '../sprite.png', 'flag', );
$spritesheet-width: 36px;
$spritesheet-height: 12px;
$spritesheet-image: '../sprite.png';
$spritesheet-sprites: ($flag-hover, $flag, );
$spritesheet: (36px, 12px, '../sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
