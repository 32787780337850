@import "mixin.scss";

.pc {
  display: block !important;
  @include mobile {
    display: none !important;
  }
}
.mb {
  display: none !important;
  @include mobile {
    display: block !important;
  }
}
// Reset css
*{ 
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
}
// Setup Scale
html {
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
}
#wrapper {
  position: relative;
  width: 2000px;
  transform-origin: top left;
  overflow-x: hidden;
  overflow-y: hidden;
  @include mobile {
    width: 768px;
  }
}

.banner-outer {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  visibility: hidden;
}
.banner {
  @extend .banner-outer;
  #navbar {
    visibility: visible;
  }
  main {
    position: absolute;
    top: 0;
    left: 0;
    width: 2000px;
    height: 1000px;
  }
}
.outer {
  @extend .banner-outer;
  width: 2000px;
  height: 1000px;
  @include mobile {
     width: 768px;
  }
  
}
.hover-light:hover {
  filter: brightness(120%);
  cursor:pointer;
  @include mobile {
    filter: unset;
  }
}
// .layer {
//   position: fixed;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background-color: black;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   opacity: 1;
//   transition: opacity .5s linear;
//   z-index: 10;
// };
.layer {
    width: 100%;
    height: 100%;
    background-color: black;
    position: fixed;
    z-index: 100;
    opacity: 1;
    transition: opacity .5s linear;
}
.lds-dual-ring {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    display: inline-block;
    width: 80px;
    height: 80px;
    opacity: 1;
    transition: opacity .3s linear;
}
.lds-dual-ring::after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);

    }
    100% {
      transform: rotate(360deg);
    }
}

@font-face {
  font-family: mainFont;
  // src: url('../font/NeueFrutigerWorld-Bold.otf');
}
@font-face {
  font-family: navbar;
  src: url('../font/NeueFrutigerWorld-Bold.otf');
}
@font-face {
  font-family: footer;
  src: url('../font/NeueFrutigerWorld-Light.otf');
}
@font-face {
  font-family: dinpro_bold;
  src: url('../font/GMV_DIN_PRO-BOLD.TTF');
}
@font-face {
  font-family: dinpro_medium;
  src: url('../font/GMV_DIN_PRO-MEDIUM.TTF');
}
@font-face {
  font-family: tungsten_bold;
  src: url('../font/SVN-TUNGSTEN-BOLD.OTF');
}
#wrapper,.banner,.outer {
  font-family: mainFont;
}