@mixin image($folder,$filename,$width: auto, $height: auto) {
  background-image: url('../images/#{$folder}/#{$filename}');
  background-repeat: no-repeat;
  width: $width;
  height: $height;
}
@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}