@import "sprite.scss";
@import "../../main/scss/mixin.scss";

#block_5 {
  position: relative;

  color: #ebd190;
  @include mobile {
    .desktop {
      display: none !important;
    }
  }
  .background {
    img {
      width: 100%;
    }
  }
  .content {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    @include mobile {
    }
    .news {
      width: 100%;
      height: 100%;
      padding-bottom: 1%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      .link {
        position: absolute;
        right: 19%;
        top: 15%;
        text-decoration: none;
        color: #6f553b;
        font-family: dinpro_bold;
        font-size: 16px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        gap: 8px;
        z-index: 5;
        @include mobile {
          transform: scale(1.3);
          z-index: 5;
          right: 7%;
          top: 86%;
        }
        &:after {
          content: "";
          @include sprite($btn_href);
          display: block;
          position: relative;
          top: 1px;
        }
      }
      .swiper {
        width: 64%;
        height: 100%;
        padding-top: 15%;
        @include mobile {
          width: 73%;
          padding-top: 50%;
          overflow: unset;
        
          .swiper-slide {
            
          }
          .swiper-slide-active {
            left: unset;
          }
        }
      }
      &_list {

        ul {
          display: flex;
          gap: 24px;
        }
      }
      &_item {
        display: flex;
        flex-direction: column;
        width: 395px;
        padding-bottom: 16px;
        text-decoration: none;

        position: relative;
        overflow: hidden;
        &:before {
          background-color: #ff4655;
          content: "";
          display: block;
          position: absolute;
          height: 224px;
          width: 100%;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          -webkit-transform: translateX(-120%) skewX(-10deg);
          transform: translateX(-120%) skewX(-10deg);
          mix-blend-mode: multiply;
          transition: -webkit-transform .3s cubic-bezier(.215,.61,.355,1);
          transition: transform .3s cubic-bezier(.215,.61,.355,1);
          transition: transform .3s cubic-bezier(.215,.61,.355,1),-webkit-transform .3s cubic-bezier(.215,.61,.355,1);
          @include mobile {
            display: none;
          }
        }
        &:hover {
          &:before {
            -webkit-transform: translateX(0) skewX(-10deg) scaleX(1.25);
            transform: translateX(0) skewX(-10deg) scaleX(1.25);
          }
          
        }
        @include mobile {
          transform: scale(1.3);
          transform-origin: left top;
        }
        .text {
          position: relative;
          top: 6px;
          display: flex;
          flex-direction: column;
          text-transform: uppercase;
          text-decoration: none;
          color: black;
          font-family: dinpro_bold;
          @include mobile {
            width: 395px;
          }
          .title {
            font-size: 19px;
            margin-top: 2px;
          }
        }
      }
    }
  }
 
}
