@import "sprite.scss";
@import "../../main/scss/mixin.scss";


#footer {
  position: relative;
  .background {
    img {
      width: 100%;
    }
  }
  .content {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    font-family: footer;
    @include mobile {
      padding: 0 5%;
    }
    .list-btn {
      display: flex;
      justify-content: center;
      gap: 22px;
      margin-top: 32px;
      @include mobile {
        display: none;
      }
      .btn_fb {
        @include sprite($btn_fb);
        transform: scale(1.56);
        transform-origin: top right;
        &:hover {
          @include sprite($btn_fb-hover);
        }
      }
      .btn_comu {
        @include sprite($btn_comu);
        &:hover {
          @include sprite($btn_comu-hover);
        }
      }
      .btn_dis {
        @include sprite($btn_dis);
        &:hover {
          @include sprite($btn_dis-hover);
        }
      }
    }
    .logo {
      @include sprite($logo);
      display: block;
      margin: 28px auto;
      @include mobile {
        transform: scale(1.5);
        margin: 54px auto;
      }
    }
    .text {
      font-size: 12px;
      color: #b0b0b0;
      text-align: center;
      @include mobile {
        font-size: 24px;
        color: white;
      }
    }
  }
 
}
