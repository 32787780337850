// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$bg-floatright-name: 'bg_floatright';
$bg-floatright-x: 0px;
$bg-floatright-y: 78px;
$bg-floatright-offset-x: 0px;
$bg-floatright-offset-y: -78px;
$bg-floatright-width: 146px;
$bg-floatright-height: 74px;
$bg-floatright-total-width: 292px;
$bg-floatright-total-height: 225px;
$bg-floatright-image: '../sprite.png';
$bg-floatright: (0px, 78px, 0px, -78px, 146px, 74px, 292px, 225px, '../sprite.png', 'bg_floatright', );
$btn-comu-hover-name: 'btn_comu-hover';
$btn-comu-hover-x: 0px;
$btn-comu-hover-y: 186px;
$btn-comu-hover-offset-x: 0px;
$btn-comu-hover-offset-y: -186px;
$btn-comu-hover-width: 38px;
$btn-comu-hover-height: 39px;
$btn-comu-hover-total-width: 292px;
$btn-comu-hover-total-height: 225px;
$btn-comu-hover-image: '../sprite.png';
$btn-comu-hover: (0px, 186px, 0px, -186px, 38px, 39px, 292px, 225px, '../sprite.png', 'btn_comu-hover', );
$btn-comu-name: 'btn_comu';
$btn-comu-x: 38px;
$btn-comu-y: 186px;
$btn-comu-offset-x: -38px;
$btn-comu-offset-y: -186px;
$btn-comu-width: 38px;
$btn-comu-height: 39px;
$btn-comu-total-width: 292px;
$btn-comu-total-height: 225px;
$btn-comu-image: '../sprite.png';
$btn-comu: (38px, 186px, -38px, -186px, 38px, 39px, 292px, 225px, '../sprite.png', 'btn_comu', );
$btn-dis-hover-name: 'btn_dis-hover';
$btn-dis-hover-x: 152px;
$btn-dis-hover-y: 186px;
$btn-dis-hover-offset-x: -152px;
$btn-dis-hover-offset-y: -186px;
$btn-dis-hover-width: 38px;
$btn-dis-hover-height: 38px;
$btn-dis-hover-total-width: 292px;
$btn-dis-hover-total-height: 225px;
$btn-dis-hover-image: '../sprite.png';
$btn-dis-hover: (152px, 186px, -152px, -186px, 38px, 38px, 292px, 225px, '../sprite.png', 'btn_dis-hover', );
$btn-dis-name: 'btn_dis';
$btn-dis-x: 190px;
$btn-dis-y: 186px;
$btn-dis-offset-x: -190px;
$btn-dis-offset-y: -186px;
$btn-dis-width: 38px;
$btn-dis-height: 38px;
$btn-dis-total-width: 292px;
$btn-dis-total-height: 225px;
$btn-dis-image: '../sprite.png';
$btn-dis: (190px, 186px, -190px, -186px, 38px, 38px, 292px, 225px, '../sprite.png', 'btn_dis', );
$btn-fb-hover-name: 'btn_fb-hover';
$btn-fb-hover-x: 76px;
$btn-fb-hover-y: 186px;
$btn-fb-hover-offset-x: -76px;
$btn-fb-hover-offset-y: -186px;
$btn-fb-hover-width: 38px;
$btn-fb-hover-height: 39px;
$btn-fb-hover-total-width: 292px;
$btn-fb-hover-total-height: 225px;
$btn-fb-hover-image: '../sprite.png';
$btn-fb-hover: (76px, 186px, -76px, -186px, 38px, 39px, 292px, 225px, '../sprite.png', 'btn_fb-hover', );
$btn-fb-name: 'btn_fb';
$btn-fb-x: 114px;
$btn-fb-y: 186px;
$btn-fb-offset-x: -114px;
$btn-fb-offset-y: -186px;
$btn-fb-width: 38px;
$btn-fb-height: 39px;
$btn-fb-total-width: 292px;
$btn-fb-total-height: 225px;
$btn-fb-image: '../sprite.png';
$btn-fb: (114px, 186px, -114px, -186px, 38px, 39px, 292px, 225px, '../sprite.png', 'btn_fb', );
$btn-register-hover-name: 'btn_register-hover';
$btn-register-hover-x: 0px;
$btn-register-hover-y: 0px;
$btn-register-hover-offset-x: 0px;
$btn-register-hover-offset-y: 0px;
$btn-register-hover-width: 146px;
$btn-register-hover-height: 78px;
$btn-register-hover-total-width: 292px;
$btn-register-hover-total-height: 225px;
$btn-register-hover-image: '../sprite.png';
$btn-register-hover: (0px, 0px, 0px, 0px, 146px, 78px, 292px, 225px, '../sprite.png', 'btn_register-hover', );
$btn-register-name: 'btn_register';
$btn-register-x: 146px;
$btn-register-y: 0px;
$btn-register-offset-x: -146px;
$btn-register-offset-y: 0px;
$btn-register-width: 146px;
$btn-register-height: 78px;
$btn-register-total-width: 292px;
$btn-register-total-height: 225px;
$btn-register-image: '../sprite.png';
$btn-register: (146px, 0px, -146px, 0px, 146px, 78px, 292px, 225px, '../sprite.png', 'btn_register', );
$btn-top-name: 'btn_top';
$btn-top-x: 223px;
$btn-top-y: 78px;
$btn-top-offset-x: -223px;
$btn-top-offset-y: -78px;
$btn-top-width: 48px;
$btn-top-height: 49px;
$btn-top-total-width: 292px;
$btn-top-total-height: 225px;
$btn-top-image: '../sprite.png';
$btn-top: (223px, 78px, -223px, -78px, 48px, 49px, 292px, 225px, '../sprite.png', 'btn_top', );
$icon-dropdown-name: 'icon-dropdown';
$icon-dropdown-x: 283px;
$icon-dropdown-y: 78px;
$icon-dropdown-offset-x: -283px;
$icon-dropdown-offset-y: -78px;
$icon-dropdown-width: 9px;
$icon-dropdown-height: 6px;
$icon-dropdown-total-width: 292px;
$icon-dropdown-total-height: 225px;
$icon-dropdown-image: '../sprite.png';
$icon-dropdown: (283px, 78px, -283px, -78px, 9px, 6px, 292px, 225px, '../sprite.png', 'icon-dropdown', );
$icon-nav-hover-name: 'icon-nav_hover';
$icon-nav-hover-x: 271px;
$icon-nav-hover-y: 78px;
$icon-nav-hover-offset-x: -271px;
$icon-nav-hover-offset-y: -78px;
$icon-nav-hover-width: 12px;
$icon-nav-hover-height: 8px;
$icon-nav-hover-total-width: 292px;
$icon-nav-hover-total-height: 225px;
$icon-nav-hover-image: '../sprite.png';
$icon-nav-hover: (271px, 78px, -271px, -78px, 12px, 8px, 292px, 225px, '../sprite.png', 'icon-nav_hover', );
$icon-nav-name: 'icon-nav';
$icon-nav-x: 271px;
$icon-nav-y: 86px;
$icon-nav-offset-x: -271px;
$icon-nav-offset-y: -86px;
$icon-nav-width: 12px;
$icon-nav-height: 8px;
$icon-nav-total-width: 292px;
$icon-nav-total-height: 225px;
$icon-nav-image: '../sprite.png';
$icon-nav: (271px, 86px, -271px, -86px, 12px, 8px, 292px, 225px, '../sprite.png', 'icon-nav', );
$logo-mb-name: 'logo-mb';
$logo-mb-x: 146px;
$logo-mb-y: 78px;
$logo-mb-offset-x: -146px;
$logo-mb-offset-y: -78px;
$logo-mb-width: 77px;
$logo-mb-height: 62px;
$logo-mb-total-width: 292px;
$logo-mb-total-height: 225px;
$logo-mb-image: '../sprite.png';
$logo-mb: (146px, 78px, -146px, -78px, 77px, 62px, 292px, 225px, '../sprite.png', 'logo-mb', );
$logo-name: 'logo';
$logo-x: 48px;
$logo-y: 152px;
$logo-offset-x: -48px;
$logo-offset-y: -152px;
$logo-width: 38px;
$logo-height: 31px;
$logo-total-width: 292px;
$logo-total-height: 225px;
$logo-image: '../sprite.png';
$logo: (48px, 152px, -48px, -152px, 38px, 31px, 292px, 225px, '../sprite.png', 'logo', );
$menu-mb-name: 'menu-mb';
$menu-mb-x: 0px;
$menu-mb-y: 152px;
$menu-mb-offset-x: 0px;
$menu-mb-offset-y: -152px;
$menu-mb-width: 48px;
$menu-mb-height: 34px;
$menu-mb-total-width: 292px;
$menu-mb-total-height: 225px;
$menu-mb-image: '../sprite.png';
$menu-mb: (0px, 152px, 0px, -152px, 48px, 34px, 292px, 225px, '../sprite.png', 'menu-mb', );
$spritesheet-width: 292px;
$spritesheet-height: 225px;
$spritesheet-image: '../sprite.png';
$spritesheet-sprites: ($bg-floatright, $btn-comu-hover, $btn-comu, $btn-dis-hover, $btn-dis, $btn-fb-hover, $btn-fb, $btn-register-hover, $btn-register, $btn-top, $icon-dropdown, $icon-nav-hover, $icon-nav, $logo-mb, $logo, $menu-mb, );
$spritesheet: (292px, 225px, '../sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
