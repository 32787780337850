@import "sprite.scss";
@import "../../main/scss/mixin.scss";

#block_6 {
  position: relative;

  @include mobile {
    .desktop {
      display: none !important;
    }
  }
  .background {
    img {
      width: 100%;
    }
  }
  .content {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    padding-left: 17.7%;
    padding-top: 7%;
    @include mobile {
      padding-left: 0;
      padding-top: 9%;
    }
    .title {
      font-size: 115px;
      font-family: tungsten_bold;
      text-transform: uppercase;
      color: #313030;
      @include mobile {
        font-size: 82px;
        text-align: center;
      }
    }
    .list-btn {
      display: flex; 
      gap: 32px;
      margin-left: 62px;
      margin-top: 31px;
      @include mobile {
        margin-left: 0;
        justify-content: center;
        margin-top: 52px;
      }
      .btn {
        font-family: dinpro_bold;
        color: #313030;
        border: 1px solid #313030;
        text-transform: uppercase;
        text-decoration: none;
        font-size: 32px;
        padding: 12px 56px;
        border-radius: 0;
        background-color: transparent;
        opacity: .2;
        &.active { 
          opacity: 1;
        }
      }
    }
    .box {
      margin-left: 82px;
      font-family: dinpro_bold;
      font-size: 18px;
      margin-top: 30px;
      width: 36%;
      display: none;
      @include mobile {
        width: 88%;
        margin-left: 52px;
        margin-top: 70px;
        font-size: 24px;
        line-height: 34px;
      }
      &.active {
        display: block;
      }
      ul {
        list-style: square;
        color: #947c5f;
        li {
          margin-bottom: 10px;
          @include mobile {
            margin-bottom: 32px;
          }
          .text:after {
            margin-left: 8px;
            content: "";
            @include sprite($icon-down);  
            display: inline-block; 
          }
          &.active {
            .text:after {
              @include sprite($icon-up);
            }
          }
          .text {
            color: #313030;
            cursor: pointer;
            &-show {
              display: none;
              font-family: dinpro_medium;
              color: #5b4b38;
              margin-top: 12px;
              position: relative;
              a {
                color: #5b4b38;
                font-weight: bold;
              }
              &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: -16px;
                transform: translateY(-50%);
                width: 1px;
                height: 100%;
                background-color: #947c5f;
              }
            }
          }
          &.active {
            .text-show {
              display: block;
            }
          }
        }
      }
    }
    .link {
      margin-left: 520px;
      margin-top: 60px;
      text-decoration: none;
      color: #6f553b;
      font-family: dinpro_bold;
      font-size: 16px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      gap: 8px;
      @include mobile {
        transform: scale(1.3);
        margin-top: 90px;
      }
      &:after {
        content: "";
        @include sprite($btn_href);
        display: block;
        position: relative;
        top: 1px;
      }
    }
  }
 
}
